<template>
    <div v-if="isLoading">
        <div class="text-center text-danger screen-center">
            <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
            <strong>Cargando...</strong>
        </div>
    </div>
    <div v-else>
        <div class="row">
            <div class="col-sm-5">
                <div class="breadcrumb">
                    <h1>Modificar Paquete</h1>
                    <div class="ml-3 pt-2">
                        <span class="font-weight-bold">
                            N°: {{ numeroRegistro }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-sm-12">
                <div class="card text-left">
                    <div class="card-header">
                        <div class="col-sm-5">
                            <button class="btn th-custom-color" 
                             @click="update">
                                Guardar
                            </button>
                            <router-link :to="{ name: 'paquete-index' }">
                                <button
                                    style="margin-left: 10px;"
                                    class="btn btn-white border border-light"
                                >
                                    Regresar
                                </button>
                            </router-link>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-sm-12 py-3 px-2">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Nombre Paquete:</label>
                                            </div>
                                            <div class="col-sm-7">
                                                <input type="text"
                                                    class="form-control"
                                                    autocomplete="off"
                                                    v-model="nombrePaquete"
                                                >
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Modalidad:</label>
                                            </div>
                                            <div class="col-sm-7">
                                                <multiselect
                                                    v-model="modalidadSelected"
                                                    :options="modalidades"
                                                    track-by="id"
                                                    label="descripcion"
                                                    placeholder="Seleccionar"
                                                    deselectLabel=""
                                                    selectLabel="Seleccionar"
                                                    :searchable="true"
                                                    @select="selectModalidad"
                                                    >
                                                </multiselect>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="row mb-2">
                                            <div class="col-sm-3 pr-0 text-right">
                                                <label class="col-form-label">Tipo:</label>
                                            </div>
                                            <div class="col-sm-7">
                                                <multiselect
                                                    v-model="tipoSelected"
                                                    :options="tipos"
                                                    track-by="id"
                                                    label="descripcion"
                                                    placeholder="Seleccionar"
                                                    deselectLabel=""
                                                    selectLabel="Seleccionar"
                                                    :searchable="true"
                                                    >
                                                </multiselect>
                                            </div>
                                        </div>
                                        <div style="border:1px solid; padding-top:10px" v-show="desplegar">
                                            <div class="row mb-2">
                                                <div class="col-sm-3 pr-0 text-right">
                                                    <label class="col-form-label">Cantidad de Timbre:</label>
                                                </div>
                                                <div class="col-sm-3">
                                                    <input type="text"
                                                        class="form-control"
                                                        autocomplete="off"
                                                        v-model="cantidadTimbre"
                                                    >
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-sm-3 pr-0 text-right">
                                                    <label class="col-form-label">Tiempo:</label>
                                                </div>
                                                <div class="col-sm-7">
                                                    <multiselect
                                                        v-model="tiempoSelected"
                                                        :options="tiempos"
                                                        track-by="id"
                                                        label="descripcion"
                                                        placeholder="Seleccionar"
                                                        deselectLabel=""
                                                        selectLabel="Seleccionar"
                                                        :searchable="true">
                                                    </multiselect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="row mb-2">
                                            <div class="col-sm-2 pr-0 text-right">
                                                <label class="col-form-label">Moneda:</label>
                                            </div>
                                            <div class="col-sm-4">
                                                <multiselect
                                                        v-model="monedaSelected"
                                                        :options="monedas"
                                                        track-by="id"
                                                        label="descripcion"
                                                        placeholder="Seleccionar"
                                                        deselectLabel=""
                                                        selectLabel="Seleccionar"
                                                        :searchable="true">
                                                    </multiselect>
                                            </div>
                                            <div class="col-sm-2 pr-0 text-right">
                                                <label class="col-form-label">Importe Total:</label>
                                            </div>
                                            <div class="col-sm-4">
                                                <vue-numeric v-model="importeTotal" 
                                                    v-bind:minus="false" v-bind:min="0" 
                                                    v-bind:precision="2"
                                                class="form-control"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { call, get, sync } from 'vuex-pathify';
import Multiselect from 'vue-multiselect'
import VueNumeric from 'vue-numeric'


export default {
    name: "Edit",
    components: {
        Multiselect,
        VueNumeric,
    },
    created() {
        this.getDataEdit();
    },
    data() {
        return {};
    },
    methods: {
        ...call("paquete/paqueteUpdate", [
            "getDatoInicial",
            "updatePaquete"
        ]),
        async getDataEdit() {
            const EDIT_OK = await this.getDatoInicial(atob(this.$route.params.id));
            if (EDIT_OK) {
                this.isLoading = false;
            }
        },
        async update(){
            this.isLoading = true;
            const UPDATE_OK = await this.updatePaquete(atob(this.$route.params.id));
            if (UPDATE_OK) {
                this.$router.push({ name: 'paquete-index' });
            }
            this.isLoading = false;
        },
        selectModalidad(item) {
            this.tiempoSelected = null;
            this.cantidadTimbre = '';
            this.desplegar = false;
            if (item.id == 2) {
                this.desplegar = true;
            }
        },
    },
    computed: {
        ...get("paquete/paqueteUpdate", [
            "tiempos",
            "modalidades",
            "tipos",
            "monedas", 
            "numeroRegistro",
        ]),
        ...sync("paquete/paqueteUpdate", [
            "tiempoSelected",
            "isLoading",
            "modalidadSelected",
            "tipoSelected",
            "desplegar",
            "monedaSelected",
            "importeTotal",
            "nombrePaquete",
            "cantidadTimbre",
        ]),
    },
    watch: {
        modalidadSelected(new1)
        {
            if (new1 && new1.id === 2) {
                this.desplegar = true;
            }
        }
    },
    beforeDestroy() {
        this.$store.dispatch('paquete/paqueteUpdate/reset');
    }
}
</script>

